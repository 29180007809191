import React from "react";
import { Link } from "gatsby";

// Components
import { Layout } from "../components/common";

const NotFoundPage = () => (
    <Layout>
        <div className="banner-figure four-o-four" style={{ backgroundImage: `url(/404.png)` }}>
            <br/>
            <br/>
            <h5>This is probably not the place you expected to land. Tap <Link to="/">home</Link> to start over.</h5>
        </div>
    </Layout>
);

export default NotFoundPage;
